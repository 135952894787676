import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL


export default {
  install(Vue) {
    const api = axios.create({
      baseURL: baseURL,
      headers: {
        common: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    })

    api.setToken = token => api.defaults.headers.common.Authorization = `Bearer ${token}`
    api.removeToken = () => delete api.defaults.headers.common.Authorization
    
    api.interceptors.response.use( res => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return res;
    }, err => {
      if ( err?.response.status === 401 ) {
        Vue.config.globalProperties.$token.remove()
        Vue.config.globalProperties.$router.push('/login')
      }
      Vue.config.globalProperties.$notification.error( err?.response?.data?.message || err.message )

      return Promise.reject(err)
    })


    Vue.config.globalProperties.$api = api
  }
}