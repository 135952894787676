<template lang="pug">
it-popover(:disabled="closed" :placement="placement")
  it-button(type="danger" :loading="loading" @click="closed = false") Удалить
  template(#content)
    .popover
      .popover-confirm Вы действительно хотите удалить
      .popover-actions
        it-button.popover__action(type="primary" outlined @click="closed = true") Нет
        it-button.popover__action(type="danger" @click="confirmDelete") Да
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'DeleteButton',
  props: {
    placement: {
      type: String,
      default: 'bottom'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click:confirm'],
  data() {
    return { 
      closed : true
    }
  },

  methods: {
    confirmDelete() {
      this.$emit('click:confirm')
      this.closed = true
    }
  }
}
</script>