export default {
  namespaced: true,

  state: () => ({
    data: null
  }),

  mutations: {
    set: (state, token) => state.data = token,
    remove: state => state.data = null 
  }
}