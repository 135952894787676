export default {
  install(Vue) {
    Vue.config.globalProperties.$excel = {

      /** @payload = string[][]  */  
      copy:  async function(payload) {
        
        if ( !navigator?.clipboard?.writeText ) {
          Vue.config.globalProperties.$notification.error('Не удалось скопировать')
          Vue.config.globalProperties.$notification.info('Обновите версию браузера')
          return
        }
        
        const data = payload.map( row => {
          const arr = row.map( elem => `${elem || ''}` )
          return arr.join('\t')
        });
        const copyString = data.join('\r\n')

        try {
          await navigator.clipboard.writeText(copyString)
          Vue.config.globalProperties.$notification.success('Скопировано в буфер обмена')
        } catch (err) {
          console.log(err)
          Vue.config.globalProperties.$notification.error(err?.message || 'Произошла ошибка')
        }
      }

    }
  }
}