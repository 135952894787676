<template lang="pug">
.data-table-wrapper
  table.data-table
    thead: tr
      td(v-if="actions && isAdmin") Действия
      td(v-for="header in headers" :key="header.title + header.value" @click="sort(header)")
        .data-table-head__td-content
          span.data-table-thead__td-text {{ header.title }} 
          it-icon.data-table-td__sort-icon(v-show="sortFilter === header.value && sortDirection === 'ASC'" name="arrow_downward")
          it-icon.data-table-td__sort-icon(v-show="sortFilter === header.value && sortDirection === 'DESC'" name="arrow_upward")

    tbody
      row(
        v-for="(item, index) in filteredItems" :key="item.id || index"
        :headers="headers" :actions="actions" :item="item" :modelName="modelName"
        :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="item => $emit('update:item', item)"
        :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="id => $emit('delete:item', id)")

        template(v-for="header in headers" :key="header.value" v-slot:[header.value]="{ disabled, itemCopy }")
          slot(:name="header.value" :item="getItemProxy(item.id)" :itemCopy="itemCopy" :disabled="disabled")

      tr(v-show="items.length === 0 && !loading")
        td.data-table__empty-part(:colspan="tableColumns") No Data
      tr(v-show="loading")
        td.data-table__loader-part(:colspan="tableColumns"): it-loading.data-table__loader-part-loader(:radius="18" :stroke="3")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import Row from './Row'


export default {
  name: 'DataTable',
  components: {Row},
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    /**
     * @headers = headerItem[]
     * @headerItem = {
     *  title: String,
     *  value: String,
     *  inputType: String (text)
     *  options: Array  (for select inputType)
     *  readonly: Boolean (false),
     *  boolean: Boolean (false),
     *  sortable: Boolean (false)
     * }
     */
    headers: {
      required: true
    },
    actions: {
      type: Boolean,
      default: true
    },
    defaultSortFilter: String,
    defaultSortDirection: {
      type: String,
      validator: val => ['ASC', 'DESC'].includes(val)
    },

    items: {
      type: Array,
      required: true
    },

    editApiMethod: String,
    editApiUrl: String,
    deleteApiMethod: String,
    deleteApiUrl: String,
    modelName: String
  },
  emits: ['update:item', 'delete:item'],
  data() {
    return {

      sortFilter: null,
      sortDirection: 'ASC'

    }
  },

  computed: {
    tableColumns() {
      return this.actions ? this.headers.length + 1 : this.headers.length
    },

    filteredItems() {
      const itemsCopy = this._clone(this.items)
      return itemsCopy.sort( (a, b) => {

        if ( this.sortDirection === 'ASC' ) {
          if ( !isNaN(a) && !isNaN(b) ) return +a[this.sortFilter] - +b[this.sortFilter]
          else {
            if ( b[this.sortFilter] < a[this.sortFilter] ) return 1
            if ( b[this.sortFilter] > a[this.sortFilter] ) return -1
            return -0
          }
        }

        if ( this.sortDirection === 'DESC' ) {
          if ( !isNaN(a) && !isNaN(b) ) return +b[this.sortFilter] - +a[this.sortFilter]
          else {
            if ( a[this.sortFilter] < b[this.sortFilter] ) return 1
            if ( a[this.sortFilter] > b[this.sortFilter] ) return -1
            return -0
          }
        }

      })
    }
  },


  methods: {
    sort(header) {
      if ( !header.sortable ) return
      if ( this.sortFilter === header.value )
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC'
      else this.sortDirection = 'ASC'

      this.sortFilter = header.value
    },

    getItemProxy(id) {
      return this.items.find( elem => elem.id === id )
    }
  },

  mounted() {
    if ( this.defaultSortFilter ) this.sortFilter = this.defaultSortFilter
    if ( this.defaultSortDirection ) this.sortDirection = this.defaultSortDirection
  }
}
</script>