export default {
  namespaced: true,

  state: () => ({
    user: null
  }),
  getters: {
    isAdmin: state => state.user ? state.user.isAdmin : false
  },

  mutations: {
    set: (state, user) => state.user = user,
    remove: state => state.user = null
  }
}