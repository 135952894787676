import { createApp } from 'vue'
import Equal from 'equal-vue'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"

import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/plugins/api'
import token from '@/plugins/token'
import notification from '@/plugins/notification'
import excel from '@/plugins/excel'

import cloneMixin from '@/mixins/clone'
import isAdminMixin from '@/mixins/isAdmin'
import formattingDateMixin from '@/mixins/formattingDate'
import formatFileSize from '@/mixins/formatFileSize'
import queryMixin from '@/mixins/query'

import DeleteButton from '@/components/DeleteButton'
import ForbiddenModal from '@/components/ForbiddenModal'

import '@/assets/styles/reset.css'
import '@/assets/styles/variables.sass'
import 'equal-vue/dist/style.css'


createApp(App)
  .use(Equal)
  .use(Toast, {
    position: 'top-center',
    timeout: 2500,
    hideProgressBar: true
  })
  .use(store)
  .use(router)
  .use(api)
  .use(token)
  .use(notification)
  .use(excel)
  .mixin(cloneMixin)
  .mixin(isAdminMixin)
  .mixin(formattingDateMixin)
  .mixin(formatFileSize)
  .mixin(queryMixin)
  .component('DeleteButton', DeleteButton)
  .component('ForbiddenModal', ForbiddenModal)
  .mount('#app')
