import TokenService from "@/services/TokenService"


export default {
  install(Vue) {
    Vue.config.globalProperties.$token = {

      get: () => Vue.config.globalProperties.$store.state.token.data,
      set: token => {
        TokenService.set(token)
        Vue.config.globalProperties.$store.commit('token/set', token)
        Vue.config.globalProperties.$api.setToken(token)
      },
      remove: () => {
        TokenService.remove()
        Vue.config.globalProperties.$store.commit('token/remove')
        Vue.config.globalProperties.$store.commit('user/remove')
        Vue.config.globalProperties.$api.removeToken()
      }

    }
  }
}