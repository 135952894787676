import { createStore } from 'vuex'
import tokenModule from './modules/token'
import userModule from './modules/user'
import productsModule from './modules/products'


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },

  modules: {
    token: tokenModule,
    user: userModule,
    products: productsModule
  }
})
