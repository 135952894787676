import { createRouter, createWebHistory } from 'vue-router'
import TokenService from '@/services/TokenService'

import Login from '@/views/Login'
import Home from '@/views/Home.vue'
import Replications from '@/views/Replications'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/points'
  },
  {
    path: '/login',
    name: 'login',
    meta: { onlyLoggedOut: true },
    component: Login,
    beforeEnter: (to, from, next) => TokenService.get() ? next('/') : next()
  },
  {
    path: '/replications',
    name: 'replications',
    component: Replications,
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products')
  },
  {
    path: '/pricelists',
    name: 'pricelists',
    component: () => import('@/views/PriceLists')
  },
  {
    path: '/pricelists/:id',
    name: 'pricelistProducts',
    component: () => import('@/views/PriceListProducts'),
    props: true
  },
  {
    path: '/points',
    name: 'points',
    component: () => import('@/views/Points')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users')
  },
  {
    path: '/versions',
    name: 'versions',
    component: () => import('@/views/Versions')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach( async (to, from, next) => {
  const onlyLoggedOut = to.matched.some( record => record.meta?.onlyLoggedOut )
  const token = TokenService.get()
  
  return !onlyLoggedOut && !token ? next('/login') : next()
})

export default router
