<template lang="pug">
.page.login-page
  form.login-form(@submit.prevent="submit")
    .login-form__title Войти

    label.login-form-label
      .login-form-label__text Логин :
      it-input.login-form-label__input(v-model="login")
    label.login-form-label
      .login-form-label__text Пароль :
      it-input.login-form-label__input(v-model="password" type="password")

    it-button.login-form__submit(type="primary" :loading="loading") Войти
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import TokenService from '@/services/TokenService'


export default {
  name: 'LoginPage',
  data() {
    return {

      loading: false,
      login: '',
      password: ''

    }
  },

  methods: {
    async submit() {
      if ( this.loading ) return
      this.loading = true

      try {
        const { data } = await this.$api.post('/login', {
          login: this.login,
          password: this.password
        })
        this.$token.set(data.result.token)
        this.$store.commit('user/set', TokenService.getUser(data.result.token))
        this.$router.push('/')
      } catch (err) {
        console.log(err)
      }

      this.loading = false
    }
  }
}
</script>