export default {
  methods: {

    formattingDate(dateString) {
      const date = dateString ? new Date(dateString) : new Date()

      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hours = date.getHours()
      let minutes = date.getMinutes()

      if ( month.toString().length === 1 ) month = '0' + month
      if ( day.toString().length === 1 ) day = '0' + day
      if ( hours.toString().length === 1 ) hours = '0' + hours
      if ( minutes.toString().length === 1 ) minutes = '0' + minutes

      return `${year}.${month}.${day}  ${hours}:${minutes}`
    }

  }
}