<template lang="pug">
header.header

  it-button.app-menu-toggle(v-show="!isOpen" type="primary" icon="menu" @click="isOpen = true")
  it-button.app-menu-toggle(v-show="isOpen" type="primary" icon="close" @click="closeMenu")
  it-button.header__action.header__logout-btn(type="primary" outlined @click="logout") Выйти

  .app-menu(:class="{'app-menu_active': isOpen}")
    router-link.app-menu__item(to="/points" @click="closeMenu")
      it-button(type="primary" :outlined="$route.path !== '/points'" block) Точки

    router-link.app-menu__item(v-if="isAdmin" to="/replications" @click="closeMenu")
      it-button(type="primary" :outlined="$route.path !== '/replications'" block) Репликации

    router-link.app-menu__item(to="/products" @click="closeMenu")
      it-button(type="primary" :outlined="$route.path !== '/products'" block) Товары

    router-link.app-menu__item(to="/pricelists" @click="closeMenu" block)
      it-button(type="primary" :outlined="$route.path !== '/pricelists'" block) Прайслисты

    router-link.app-menu__item(v-if="isAdmin" to="/users" @click="closeMenu")
      it-button(type="primary" :outlined="$route.path !== '/users'" block) Пользователи

    router-link.app-menu__item(v-if="isAdmin" to="/versions" @click="closeMenu")
      it-button(type="primary" :outlined="$route.path !== '/versions'" block) Mobile App
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'Header',
  data() {
    return { isOpen: false }
  },
  methods: {
    closeMenu() {
      this.isOpen = false
    },

    logout() {
      this.$token.remove()
      this.$router.push('/login')
    }
  },

  mounted() {
    window.addEventListener('click', e => {
      const inElem = e.target.closest('header.header')
      if ( !inElem && this.isOpen ) this.isOpen = false
    })
  }
}
</script>