<template lang="pug">
.page.home-page
</template>

<style lang="sass" src="./home.sass"></style>


<script>
export default {
  name: 'Home',
  components: {}
}
</script>
