export default {
  namespaced: true,

  state: () => ({
    data: []
  }),

  mutations: {
    set: (state, products) => state.data = products,
    add: (state, product) => state.data = [...state.data, product],

    update: (state, product) => {
      const index = state.data.findIndex( elem => elem.id === product.id )
      state.data[index] = {...product}
    },

    delete: (state, id) => {
      const index = state.data.findIndex( elem => elem.id === id )
      state.data.splice(index, 1)
    }
  }
}