<template lang="pug">
.page.page-with-table.replications-page
  template(v-if="isAdmin")
    .replications-page-header
      .replications-page-header__title Replications
      it-button(type="primary" :loading="loading" @click="getReplications") Обновить

    data-table.page__table.replications-page__talbe(:loading="loading" :actions="false" :headers="headers" :items="replications")

  forbidden-modal(:dialog="!isAdmin")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import DataTable from '@/components/DataTable'


export default {
  name: 'ReplicationsPage',
  components: { DataTable },
  data() {
    return {

      loading: false,
      headers: [
        { title: 'Dir', value: 'dir', readonly: true, sortable: true },
        { title: 'Dist name', value: 'DistName', readonly: true, sortable: true },
        { title: 'MRName', value: 'MRName', readonly: true, sortable: true },
        { title: 'Region', value: 'Region', readonly: true, sortable: true },
        { title: 'File', value: 'file', readonly: true, sortable: true },
        { title: 'Size', value: 'size', readonly: true, sortable: true },
        { title: 'Last update', value: 'mtime', readonly: true, sortable: true }
      ],
      replications: []

    }
  },

  methods: {
    async getReplications() {
      if ( this.loading ) return
      this.loading = true

      try {
        const {data} = await this.$api.get('/replications')
        data.result.data.forEach( elem => {
          elem.mtime = this.formattingDate(elem.mtime) 
          elem.size = this.formatFileSize(elem.size)
        });
        this.replications = data.result.data
      } catch (err) {
        console.log(err)
      }

      this.loading = false
    },
  },

  mounted() {
    this.getReplications()
  }
}
</script>