<template lang="pug">
tr.data-table-row
  td.data-table-row__actions(v-if="actions && isAdmin")
    delete-button.data-table-row__action(v-show="actions && disabled" :loading="deleteLoading" @click:confirm="deleteItem(item.id)" placement="right") Delete
    it-button.data-table-row__action(v-show="actions && disabled" type="primary" outlined @click="disabled = false") Edit
    it-button.data-table-row__action(v-show="actions && !disabled" type="danger" outlined @click="cancel") Cancel
    it-button.data-table-row__action(v-show="actions && !disabled" type="success" :loading="saveLoading" @click="save") Save

  td(v-for="header in headers" :key="header.title")
    slot(:name="header.value" :disabled="disabled" :itemCopy="itemCopy")
      it-select(
        v-if="header.inputType === 'select' && !header.readonly" v-model="itemCopy[ header.value ]"
        :options="header.options" :disabled="disabled" placeholder="Не выбрано")
      it-switch(v-else-if="header.boolean" v-model="itemCopy[ header.value ]" :disabled="disabled || header.readonly")

      template(v-else)
        span(v-show="header.readonly") {{ itemCopy[ header.value ] !== undefined ? itemCopy[ header.value ] : '' }}
        it-input(v-show="!header.readonly" v-model="itemCopy[ header.value ]" :type="header.inputType || 'text'" :readonly="disabled")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'DataTableRow',
  props: {
    // from DataTable headers props
    headers: {
      required: true
    },
    actions: Boolean,
    item: {
      required: true
    },

    editApiMethod: String,
    editApiUrl: String,
    deleteApiMethod: String,
    deleteApiUrl: String,
    modelName: String

  },
  emits: ['update:item', 'delete:item'],
  data() {
    return {

      disabled: true,
      itemCopy: {},
      saveLoading: false,
      deleteLoading: false

    }
  },
  watch: {
    item: {
      handler() {
        this.itemCopy = {...this.item}
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    cancel() {
      this.disabled = true
      this.itemCopy = {...this.item}
    },
    async save() {
      this.saveLoading = true

      try {
        const { data } = await this.$api[this.editApiMethod](this.editApiUrl + '/' + this.item.id, {[this.modelName]: this.itemCopy})
        this.disabled = true
        this.$notification.success('Успешно изменено')
        this.$emit('update:item', data.result[this.modelName] || { ...this.itemCopy })
      } catch (err) {
        console.log(err)
      }

      this.saveLoading = false
    },
    async deleteItem(id) {
      if ( this.deleteLoading ) return
      this.deleteLoading = true

      try {
        await this.$api[this.deleteApiMethod](this.deleteApiUrl + '/' + id)
        this.$notification.success('Успешно удалено')
        this.$emit('delete:item', id)
      } catch (err) {
        console.log(err)
      }

      this.deleteLoading = false
    }
  }
} 
</script>