export default {
  computed: {
    queryReq() {
      return this.$route?.query?.query?.toLowerCase()
    }
  },

  methods: {
    filterByQuery(items) {
      return items.filter( item => {
        for ( let prop in item ) {
          const matches = item[prop]?.toString()?.match( new RegExp(this.queryReq, 'i') )
          if ( matches ) return true
        }

        return false
      })
    }
  }
}