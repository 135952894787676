<template lang="pug">
it-modal.forbidden-modal(v-model="dialog")
  template(#header)
    .forbidden-modal-header Нет доступа
  template(#body)
    it-icon.forbidden-modal-content-icon(name="block" color="red")
  template(#actions)
    it-button(type="primary" @click="$router.go(-1)") Понятно
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'ForbiddenModal',
  props: {
    dialog: Boolean
  }
}
</script>