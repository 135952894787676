<template lang="pug">
.preloader-wrap
  it-loading
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'Preloader'
}
</script>