<template lang="pug">
.app-container
  preloader(v-if="loading")
  Header(v-if="hasToken")
  router-view
</template>

<style lang="sass" src="./app.sass"></style>


<script>
import TokenService from './services/TokenService'
import Preloader from '@/components/Preloader'
import Header from '@/components/Header'


export default {
  name: 'App',
  components: {Preloader, Header},
  data() {
    return {

      loading: true

    }
  },
  computed: {
    hasToken() {
      return this.$store.state.token.data
    }
  },

  mounted() {
    const token = TokenService.get()
    if ( token ) {
      this.$token.set(token)
      this.$store.commit('user/set', TokenService.getUser(token))
    }

    if ( document.readyState === 'loading' ) window.addEventListener('load', () => this.loading = false)
    else this.loading = false
  }
}
</script>